@keyframes orientation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(-90deg);
  }
}

.wrapper {
  display: none;

  @media (orientation: portrait) {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    overflow: hidden;
    z-index: 10000;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.75);
  }
}

.notice {
  background: white;
  border: 1px solid #ccc;
  border-radius: 1ex;
  margin: 1em;
  padding: 2em 1em;
  text-align: center;

  svg {
    width: 200px;
    animation: orientation ease 2.5s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
  }
}
